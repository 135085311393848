import React from 'react';
import { Card, Text, Link } from 'theme-ui';
import NewsletterForm from '@components/NewsletterForm';
import Section from '@components/Section';
import useMailChimp from '@helpers/useMailChimp';

const styles = {
  link: {
    display: `block`,
    color: `heading`,
    textAlign: `center`,
    fontWeight: `medium`,
    border: '2px solid',
    borderColor: `inherit`,
    padding: 10,
    ':hover': {
      borderColor: `inherit`,
    },
  },
};

const NewsletterCompact = (props) => {
  const {
    handleSubmit,
    canSubmit,
    submitting,
    message,
    success,
  } = useMailChimp();

  return (
    <Section aside title="Promotion" {...props}>
      <Card variant="paper">
        <Text variant="h3">NewTabTodo</Text>
        <Text variant="p">
          Add todos and manage your day to day task within your browser!
        </Text>
        <Link
          variant="mute"
          target="_blank"
          title="NewTabTodo Website"
          href="https://newtabtodo.com?ref=muhammadovi.com"
          rel="noopener"
          sx={styles.link}
        >
          Get it now
        </Link>
      </Card>
    </Section>
  );
};

export default NewsletterCompact;
